import React from "react";
// CSS
import "../../Assets/css/header.css";

// Image
import MenuLogo from "../../Assets/img/menu-logo.png";
import MobileLogo from "../../Assets/img/header-icon-logo.png";
import MobileClose from "../../Assets/img/header-icon-close.png";
import IconMenu from "../../Assets/img/header-icon-menu.png";
import ArrowRight from "../../Assets/img/arrow-right.png";
import ArrowDown from "../../Assets/img/header-arrow-down.png";
import ArrowRightSmall from "../../Assets/img/arrow-right-small.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { setOpenMenu } from "./Slice/Slice";
import { LANGUAGE_CODE, setLanguage } from "../../Redux/Language";
import { useTranslation } from "react-i18next";
import { SANANCORP_SITE_URL, SANAN_BLOG_URL } from "../../Common/Constant";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [isShowService, setShowMenuService] = React.useState<boolean>(false);
  const [isShowContact, setShowMenuContact] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.header.isOpen);

  const language = useSelector((state: RootState) => state.language.language);

  const displayLanguageMapping = (language: LANGUAGE_CODE) => {
    switch (language) {
      case LANGUAGE_CODE.EN:
        return "EN";
      case LANGUAGE_CODE.JA:
        return "JP";
      case LANGUAGE_CODE.VI:
        return "VI";
    }
  };

  const languageItems = Object.values(LANGUAGE_CODE);

  const languageItemsFiltered = languageItems.filter(
    (item) => item !== language
  );

  const { t } = useTranslation();

  const onClickMenu = (category: string) => {
    switch (category) {
      case "service":
        setShowMenuContact(false);
        setShowMenuService(!isShowService);
        break;
      case "contact":
        setShowMenuContact(!isShowContact);
        setShowMenuService(false);
        break;
      case "home":
        navigate("/");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "estimate":
        navigate("/estimate-contact");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "document":
        navigate("/document-download");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "software":
        navigate("/software");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "talent":
        navigate("/talent");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "policy":
        navigate("/policy");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "about":
        navigate("/about");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "corp":
        window.open(SANANCORP_SITE_URL, "_blank");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      case "blog":
        window.open(SANAN_BLOG_URL, "_blank");
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
      default:
        setShowMenuContact(false);
        setShowMenuService(false);
        break;
    }
  };

  function onClickLanguage(language: LANGUAGE_CODE) {
    dispatch(setLanguage(language));
  }

  return (
    <div className={`header-wrapper ${isOpen ? "header-wrapper-mobile" : ""}`}>
      {!isOpen ? (
        <div className={"header-menu"}>
          <div className={`logo`} onClick={() => onClickMenu("corp")}>
            <img src={MenuLogo} alt="menu-logo" />
          </div>
          <div
            className="icon-menu"
            onClick={() => dispatch(setOpenMenu(!isOpen))}
          >
            <img src={IconMenu} alt="" />
          </div>
          <div className="option">
            <div className="item" onClick={() => onClickMenu("home")}>
              {t("header.menu.home")}
            </div>
            <div className="item" onClick={() => onClickMenu("service")}>
              <span>
                <img
                  style={{ marginRight: 9 }}
                  src={isShowService ? ArrowDown : ArrowRight}
                  alt=""
                />
              </span>

              {t("header.menu.service")}
              <div
                className="sub-item"
                style={{
                  display: isShowService ? "block" : "none",
                }}
              >
                <div
                  className="element"
                  style={{
                    borderBottom: "1px solid #34343424",
                  }}
                  onClick={() => onClickMenu("software")}
                >
                  {t("header.menu.service.sofware")}
                  <img
                    style={{ marginLeft: 33 }}
                    src={ArrowRightSmall}
                    alt=""
                  />
                </div>
                <div className="element" onClick={() => onClickMenu("talent")}>
                  {t("header.menu.service.talent")}
                  <img
                    style={{ marginLeft: 20 }}
                    src={ArrowRightSmall}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="item" onClick={() => onClickMenu("about")}>
              {t("header.menu.about")}
            </div>
            <div className="item" onClick={() => onClickMenu("blog")}>
              {t("header.menu.blog")}
            </div>
            <div className="item" onClick={() => onClickMenu("contact")}>
              <span>
                <img
                  style={{ marginRight: 9 }}
                  src={isShowContact ? ArrowDown : ArrowRight}
                  alt=""
                />
              </span>
              {t("header.menu.contact")}
              <div
                className="sub-item"
                style={{
                  display: isShowContact ? "block" : "none",
                }}
              >
                <div
                  className="element"
                  style={{
                    borderBottom: "1px solid #34343424",
                  }}
                  onClick={() => onClickMenu("estimate")}
                >
                  <span>{t("header.menu.estimate")}</span>
                  <img
                    style={{ marginLeft: 35 }}
                    src={ArrowRightSmall}
                    alt=""
                  />
                </div>
                <div
                  className="element"
                  onClick={() => onClickMenu("document")}
                >
                  <span>{t("header.menu.document")}</span>
                  <img
                    style={{ marginLeft: 14 }}
                    src={ArrowRightSmall}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="language">
            <p className="selected">{displayLanguageMapping(language)}</p>

            {/* <img src={Line} alt="" /> */}
            {/* <p className="vi">VI</p> */}
            <div className="language-group">
              <div className="language-item"></div>
              {languageItemsFiltered.map((item) => {
                return (
                  <div
                    className="language-item"
                    onClick={() => {
                      onClickLanguage(item);
                    }}
                  >
                    <p>{displayLanguageMapping(item)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className={"header-menu-mobile"}>
          <div className="mobile-menu-group">
            <div
              className={`"logo-mobile"`}
              onClick={() => onClickMenu("corp")}
            >
              <img src={MobileLogo} alt="" />
            </div>
            <div
              className="icon-menu"
              onClick={() => dispatch(setOpenMenu(!isOpen))}
            >
              <img src={MobileClose} alt="" />
            </div>
          </div>
          <div className="option">
            <div className="item" onClick={() => onClickMenu("home")}>
              {t("header.menu.home")}
            </div>
            <div className="item" onClick={() => onClickMenu("service")}>
              <img src={isShowService ? ArrowDown : ArrowRight} alt="" />
              {t("header.menu.service")}
              <div
                className="sub-item"
                style={{
                  display: isShowService ? "block" : "none",
                }}
              >
                <div
                  className="element"
                  style={{
                    borderBottom: "1px solid #34343424",
                    paddingTop: "0px",
                    marginTop: "20px",
                  }}
                  onClick={() => onClickMenu("software")}
                >
                  {t("header.menu.service.sofware")}
                  <img
                    src={ArrowRightSmall}
                    style={{ marginLeft: 28 }}
                    alt=""
                  />
                </div>
                <div
                  className="element"
                  onClick={() => onClickMenu("talent")}
                  style={{ paddingBottom: 0 }}
                >
                  {t("header.menu.service.talent")}
                  <img
                    src={ArrowRightSmall}
                    style={{ marginLeft: 15 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="item" onClick={() => onClickMenu("about")}>
              {t("header.menu.about")}
            </div>
            <div className="item" onClick={() => onClickMenu("blog")}>
              {t("header.menu.blog")}
            </div>
            <div className="item" onClick={() => onClickMenu("contact")}>
              <img src={isShowContact ? ArrowDown : ArrowRight} alt="" />
              {t("header.menu.contact")}
              <div
                className="sub-item"
                style={{
                  display: isShowContact ? "block" : "none",
                }}
              >
                <div
                  className="element"
                  style={{
                    borderBottom: "1px solid #34343424",
                    paddingTop: "0px",
                    marginTop: "20px",
                  }}
                  onClick={() => onClickMenu("estimate")}
                >
                  <span>{t("header.menu.estimate")}</span>
                  <img
                    src={ArrowRightSmall}
                    style={{ marginLeft: 31 }}
                    alt=""
                  />
                </div>
                <div
                  className="element"
                  onClick={() => onClickMenu("document")}
                  style={{
                    paddingBottom: 0,
                  }}
                >
                  <span>{t("header.menu.document")}</span>
                  <img
                    src={ArrowRightSmall}
                    style={{ marginLeft: 15 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="language">
            {languageItems.map((item, index) => {
              return (
                <React.Fragment>
                  <p
                    className={item === language ? "selected" : ""}
                    onClick={() => onClickLanguage(item)}
                  >
                    {displayLanguageMapping(item)}
                  </p>
                  {index < languageItems.length - 1 && (
                    <p className="selected">-</p>
                  )}
                </React.Fragment>
              );
            })}
            {/* <img src={Line} alt="" /> */}
            {/* <p className="vi">VI</p> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
