const AXIOS_TIMEOUT_REQUEST = 5000;

const SANANCORP_SITE_URL = "https://sanancorp.com";
const SANANKIDS_SITE_URL = "https://sanankids.edu.vn/";
const SANANNAVI_SITE_URL = "https://sanan-navi.com/";
const SANAN_CONNECT_FACEBOOK_URL =
  "https://www.facebook.com/profile.php?id=61551839163053";
const SANAN_CONNECT_LINKEDIN_URL =
  "https://www.linkedin.com/company/sanan-connect";
const SANAN_CONNECT_TWITTER_URL = "https://twitter.com/sanan_connect";
const SANAN_BLOG_URL = "https://blog.sanan-software.com";

const SANAN_DOCUMENT_BROCHURE =
  "https://sanan-software-document.s3.ap-northeast-1.amazonaws.com/SanAn-Brochure.pdf";
const SANAN_DOCUMENT_CASESTUDY =
  "https://sanan-software-document.s3.ap-northeast-1.amazonaws.com/SanAn-CaseStudy.pdf";

export {
  AXIOS_TIMEOUT_REQUEST,
  SANANCORP_SITE_URL,
  SANANKIDS_SITE_URL,
  SANANNAVI_SITE_URL,
  SANAN_CONNECT_FACEBOOK_URL,
  SANAN_CONNECT_LINKEDIN_URL,
  SANAN_CONNECT_TWITTER_URL,
  SANAN_BLOG_URL,
  SANAN_DOCUMENT_BROCHURE,
  SANAN_DOCUMENT_CASESTUDY,
};
