import { Route, Routes } from "react-router-dom";
import { RouterType } from "./RouterType";
import { HomeRouter } from "./Home/HomeRouter";
import { DocumentRouter } from "./Document/DocumentRouter";
import { SoftwareRouter } from "./Software/SoftwareRouter";
import { TalentRouter } from "./Talent/TalentRouter";
import { PolicyRouter } from "./Policy/PolicyRouter";
import { AboutRouter } from "./About/AboutRouter";
import { MissingRoute } from "./MissingRouter";
import { PrivacyRouter } from "./Privacy/PrivacyRouter";

const BaseRouter = () => {
  const routers: RouterType[] = [
    ...HomeRouter,
    ...DocumentRouter,
    ...SoftwareRouter,
    ...TalentRouter,
    ...PolicyRouter,
    ...AboutRouter,
    ...PrivacyRouter,
  ];

  return (
    <Routes>
      <Route path="*" element={<MissingRoute />} />
      {routers.map((entry: any, idx: number) => (
        <Route key={idx} path={entry.path} element={entry.element} />
      ))}
    </Routes>
  );
};

export default BaseRouter;
